import React, {useState} from 'react';
import './App.css';
import MonacoEditor from "react-monaco-editor";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {editor} from "monaco-editor";
import Button from "react-bootstrap/Button";
import {callExecuteRule} from "./api";


function App() {
  const code = "min(3*3, 2+2)"
  const options: editor.IEditorConstructionOptions = {
    selectOnLineNumbers: true,
    minimap: {
      enabled: false,
    },
  };

  const [ruleOutput, setRuleOutput] = useState("")

  let rule = code

  const onChange = (editorText: string) => rule = editorText
  const editorDidMount = () => {}

  const testRuleClick = () => {
    console.log(rule)
    callExecuteRule(rule, null)
        .then(x => {
          console.log(x.res)
          setRuleOutput(x.res)
        })
  }

  return (
    <Container>
      <Row className="mt-4">
        <Col><h1>🐈 RuleCat</h1></Col>
      </Row>
      <Row>
        <Col md={2}>😼 This is the sidebar.</Col>
        <Col>
          <Row>
            <Col>
              <MonacoEditor
                height={500}
                language="javascript"
                theme="vs"
                value={code}
                options={options}
                onChange={onChange}
                editorDidMount={editorDidMount}
              />
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col></Col>
            <Col md={2}><Button onClick={testRuleClick}>Test Rule</Button></Col>
          </Row>
          <Row>
            <Col>
              Rule output: {ruleOutput}
            </Col>
          </Row>
        </Col>
        <Col md={2}>😻 This is another sidebar.</Col>
      </Row>
    </Container>
  );

}

export default App;
